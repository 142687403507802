import styled from "styled-components";

import {
  CONTAINERS,
  PADDINGS,
  SCREENS
} from "../../../theme/style-constants";


export const StyledSection = styled.section`
  width: 100%;
`;

export const Container = styled.div`
  max-width: ${ CONTAINERS.largeDesktop };
  width: 90%;
  margin: 0 auto;
  padding: ${ PADDINGS.container.section.laptop };
`;

export const StyledHeading = styled.h1`
  margin: 0 0 4rem;

  @media (min-width: ${ SCREENS.tablet }) {
    span {
      display: block;
    }
  }
`;

export const StyledLinks = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  margin: 0 0 5rem;
`;
