import * as React from "react";
import { graphql, useStaticQuery } from "gatsby";

import Section from "../components/Section";

import SecondaryButton from "../components/Button/SecondaryButton";

import CallToAction from "../components/CallToAction";
import Carousel, { EmblaCarousel } from "../components/Carousel";
import IndexHero from "../components/Hero/IndexHero";
import Layout from "../components/Layout";
import Scroller from "../components/Scroller";
import SEO from "../components/SEO";
import Ticker from "../components/Ticker";


const IndexPage = () => {
  const data = useStaticQuery(graphql`
    query {
      services: allMdx(
        filter: {
          frontmatter: {
            template: {
              eq: "service"
            }
          }
        }
        sort: {
          fields: [frontmatter___order]
          order: ASC
        }
      ) {
        edges {
          node {
            frontmatter {
              title
              serviceImage {
                childImageSharp {
                  gatsbyImageData(
                    width: 700
                    quality: 100
                    placeholder: TRACED_SVG
                    formats: [AUTO, WEBP, AVIF]
                  )
                }
              }
              order
            }
            fields {
              slug
            }
          }
        }
      }

      photography: allMdx(
        filter: {
          frontmatter: {
            template: {
              eq: "photography"
            }
          }
        }
        sort: {
          fields: [frontmatter___order]
          order: ASC
        }
      ) {
        edges {
          node {
            frontmatter {
              title
              photographer
              image {
                childImageSharp {
                  gatsbyImageData(
                    width: 700
                    quality: 100
                    placeholder: TRACED_SVG
                    formats: [AUTO, WEBP, AVIF]
                  )
                }
              }
              logo {
                childImageSharp {
                  gatsbyImageData(
                    width: 200
                    quality: 100
                    placeholder: TRACED_SVG
                    formats: [AUTO, WEBP, AVIF]
                  )
                }
              }
            }
          }
        }
      }

      portfolio: allMdx(
        filter: {
          frontmatter: {
            template: {
              eq: "portfolio"
            }
          }
        }
      ) {
        edges {
          node {
            frontmatter {
              title
              homeCard {
                childImageSharp {
                  gatsbyImageData(
                    width: 1200
                    quality: 80
                    placeholder: TRACED_SVG
                    formats: [AUTO, WEBP, AVIF]
                  )
                }
              }
            }
            fields {
              slug
            }
          }
        }
      }
    }
  `);

  return (
    <Layout>
      <SEO title="Home" />
      
      <IndexHero
        Component={
          <Ticker
            template="marketing-partner"
            itemWidth={ 16 }
          />
        }
      />

      <Section
        isComponentFullLength
        Component={
          <Carousel
            layout="photo"
            nodes={ data.photography.edges }
            options={
              {
                loop: true,
              }
            }
          />
        }
      />

      <Section
        sectionTitle="Our Services"
        sectionDescription="Explore Agency is a full-service digital marketing agency based in Toronto. Using design and data-driven approach, our company works with all industries."
        Component={
          <Scroller
            layout="simple"
            nodes={ data.services.edges }
          />
        }
      />

      <Section
        sectionTitle="Ready To Explore? Let's Talk."
        textAlignment="center"
        darkBackground
        Component={
          <CallToAction
            darkBackground
            ButtonComponent={
              <SecondaryButton
                linkLocation="/contact"
                linkText="Start A Project"
                darkBackground
              />
            }
          />
        }
      />

      <Section
        sectionTitle="Our Partners"
        sectionDescription="We have had the pleasure of working with small and large organizations in all industries to help redefine their digital marketing efforts."
        isComponentFullLength
        Component={
          <Ticker
            template="portfolio"
            itemWidth={ 10 }
          />
        }
      />

      <Section
        sectionTitle="Our Portfolio"
        sectionDescription="We have had the pleasure of working with small and large organizations in all industries to help redefine their digital marketing efforts."
        isComponentFullLength
        Component={
          <Carousel
            layout="portfolio"
            nodes={ data.portfolio.edges }
            service="home"
            linkLocation="/portfolio"
            linkText="Explore More"
            options={
              {
                loop: true,
              }
            }
          />
        }
      />
    </Layout>
  )
}


export default IndexPage;
