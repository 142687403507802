import * as React from "react";

import SecondaryButton from "../../Button/SecondaryButton";

import {
  Container,
  StyledHeading,
  StyledLinks,
  StyledSection
} from "./style";


const IndexHero = ({
  Component
}) => {
  return (
    <StyledSection>
      <Container>
        <StyledHeading>
          <span>Your Award-Winning </span>
          <span>Digital Marketing Agency</span>
        </StyledHeading>
        <StyledLinks>
          <SecondaryButton
            linkLocation="/contact"
            linkText="Explore Now"
          />
        </StyledLinks>
        { Component }
      </Container>
    </StyledSection>
  )
}


export default IndexHero;
